import Css from "./style.module.scss";

import { Checkbox, Input, TagsInput } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import FormContext from "contexts/FormContext";
import React, { useCallback, useContext } from "react";

const ExcludedWords = ({ value, disabled, onChange, ...restProps }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const { values: { rpaExcludedWordsToggle }, onChange: onFormChange } = useContext(FormContext);

  const handleRpaPausedToggleChange = useCallback(() => {
    onFormChange({ name: "rpaExcludedWordsToggle", value: !rpaExcludedWordsToggle });
  }, [rpaExcludedWordsToggle, onFormChange]);

  return (
    <div className={Css.excludedWords}>
      {rpaExcludedWordsToggle
        ? (
          <>
            <TagsInput
              value={value}
              disabled={disabled}
              className={Css.input}
              onChange={onChange}
              placeholder={messages.rpaExcludedWordsPlaceholder}
              invalid={!value?.length}
              {...restProps} />
          </>
        )
        : <Input
          {...restProps}
          disabled
          className={Css.input}
          value={uiTexts.noExclusions} />}
      <Checkbox
        toggle
        data-tooltip={rpaExcludedWordsToggle ? uiTexts.turnOff : uiTexts.turnOn}
        value={rpaExcludedWordsToggle}
        onClick={handleRpaPausedToggleChange} />
    </div>
  );
};

export default React.memo(ExcludedWords);
