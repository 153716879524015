import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getActiveOrganization } from "selectors/organizations";
import { getPricesData } from "selectors/metaData";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const { BUSINESS_TYPES } = Constants;

const REGULAR_FEATURES_ICONS = [
  [Icons.Devices, "#F3D434"],
  [Icons.Flag, "#0267D2"],
  [Icons.CheckSquareOffset, "#FA2F47"],
  [Icons.Robot, "#54D2CB"],
  [Icons.FileText, "#9550ED"],
  [Icons.FileLock, "#ED6135"],
  [Icons.CloudArrowDown, "#F4D94D"],
  [Icons.ChatsTeardrop, "#1DB173"],
  [Icons.PuzzlePiece, "#F3D434"],
  [Icons.SquaresFour, "#FA2F47"]
];

const ROBOTIC_FEATURES_ICONS = [
  [Icons.Lightning, "#FA2F47"],
  [Icons.CheckSquareOffset, "#F3D434"],
  [Icons.Eye, "#1DB173"],
  [Icons.Bank, "#ED6135"],
  [Icons.Atom, "#9550ED"],
  [Icons.CubeTransparent, "#54D2CB"]
];

const SelectTypeBlock = ({ businessType, setBusinessType }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const { currency, annualBilling, appsumoValue } = useSelector(getActiveOrganization);

  const { monthlyPrices, annualPrices } = useSelector(getPricesData);

  const prices = annualBilling ? annualPrices : monthlyPrices;

  const [, ...regularWorkflowFeatures] = messages.onboardingRegularWorkflow;

  const [, allRegularFeaturesText, ...rpaWorkflowFeatures] = messages.onboardingRpaWorkflow;

  const regularPrice = useMemo(() => {
    try {
      const price = prices.regular[currency] || prices.regular[Constants.DEFAULT_CURRENCY];

      return Utils.toMoneyString(
        price,
        prices.regular[currency] ? currency : Constants.DEFAULT_CURRENCY,
        price % 1 ? 1 : 0
      );
    } catch (error) {
      return null;
    }
  }, [currency, prices]);

  const roboticPrice = useMemo(() => {
    try {
      const price = prices.robotic[currency] || prices.robotic[Constants.DEFAULT_CURRENCY];

      return Utils.toMoneyString(
        price,
        prices.robotic[currency] ? currency : Constants.DEFAULT_CURRENCY,
        price % 1 ? 1 : 0
      );
    } catch (error) {
      return null;
    }
  }, [currency, prices]);

  const pricePeriodLabel = useMemo(() => {
    return (annualBilling ? uiTexts.year : uiTexts.month).toLowerCase();
  }, [uiTexts, annualBilling]);

  const handleBlockClick = useCallback(({ currentTarget: { dataset: { type } } }) => {
    setBusinessType(type);
  }, [setBusinessType]);

  return (
    <div className={Css.selectRpaBlock}>
      <div className={Css.header}>{messages.addBusinessFirstStep}</div>
      <div className={Css.blocks}>
        {!appsumoValue && (
          <div
            data-type={BUSINESS_TYPES.REGULAR}
            className={classNames(Css.block, businessType === BUSINESS_TYPES.REGULAR && Css.selected)}
            onClick={handleBlockClick}>
            <div className={Css.title}>
              <div>{uiTexts.dataEntryAutomationHub}</div>
            </div>
            <div className={Css.price}>
              {regularPrice ? <><b>{regularPrice}</b><span>{` / ${pricePeriodLabel}`}</span></> : null}
            </div>
            <div>{uiTexts.keyFeatures}</div>
            <div className={Css.items}>
              {regularWorkflowFeatures.map((text, index) => {
                const [Icon, color] = REGULAR_FEATURES_ICONS[index] || [Icons.Check, "#000"];

                return (
                  <div key={text} className={Css.item}>
                    <Icon color={color} />
                    <div>{text}</div>
                  </div>
                );
              })}
            </div>
            <div className={Css.integration}>
              <div className={Css.text}>{uiTexts.integrationWith}</div>
              {IntegrationServices.getFullIntegratedList().map(({ value, label, logo }) => {
                return (
                  <img key={value} src={logo} title={label} alt={label} />
                );
              })}
            </div>
          </div>
        )}
        <div
          data-type={BUSINESS_TYPES.ROBOTIC}
          className={classNames(Css.block, businessType === BUSINESS_TYPES.ROBOTIC && Css.selected)}
          onClick={handleBlockClick}>
          <div className={Css.title}>
            <div>{uiTexts.roboticAiBookkeeper}</div>
          </div>
          <div className={Css.price}>
            {roboticPrice ? <><b>{roboticPrice}</b><span>{` / ${pricePeriodLabel}`}</span></> : null}
          </div>
          <div>{uiTexts.keyFeatures}</div>
          <div className={Css.items}>
            <div className={Css.item}>
              <Icons.CheckCircle weight="bold" className={CommonCss.positiveText} />
              <div><b>{allRegularFeaturesText}</b></div>
            </div>
            {rpaWorkflowFeatures.map((text, index) => {
              const [Icon, color] = ROBOTIC_FEATURES_ICONS[index] || [Icons.Check, "#000"];

              return (
                <div key={text} className={Css.item}>
                  <Icon color={color} />
                  <div>{text}</div>
                </div>
              );
            })}
          </div>
          <div className={Css.warning}>
            <Icons.WarningCircle />
            <span>{messages.postSignUpModalWarning}</span>
          </div>
          <div className={Css.integration}>
            <div className={Css.text}>{uiTexts.integrationWith}</div>
            {IntegrationServices
              .getFullIntegratedList()
              .filter(({ availableForRpa }) => availableForRpa)
              .map(({ value, label, logo }) => {
                return (
                  <img key={value} src={logo} title={label} alt={label} />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SelectTypeBlock);
