import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import { Preloader } from "lib/common";
import { useDispatch } from "react-redux";
import Lightbox from "react-image-lightbox";
import React, { useCallback, useState } from "react";
import UiActions from "actions/UiActions";
import classNames from "classnames";

const ImageViewer = ({ sources }) => {
  const dispatch = useDispatch();

  const [imageIndex, setImageIndex] = useState(0);

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleClose = useCallback(() => {
    dispatch(UiActions.hideModalImages());
  }, [dispatch]);

  const handleImageLoaded = useCallback(() => {
    setImageLoaded(true);
  }, []);

  const handleMovePrev = useCallback(() => {
    setImageIndex(imageIndex - 1);
  }, [imageIndex]);

  const handleMoveNext = useCallback(() => {
    setImageIndex(imageIndex + 1);
  }, [imageIndex]);

  return (
    <>
      <Lightbox
        animationDuration={150}
        mainSrc={sources[imageIndex]}
        nextSrc={sources[imageIndex + 1]}
        prevSrc={sources[imageIndex - 1]}
        reactModalProps={{ overlayClassName: classNames(Css.imageViewer, CommonCss.zIndexB) }}
        onCloseRequest={handleClose}
        onImageLoad={handleImageLoaded}
        onImageLoadError={handleImageLoaded}
        onMovePrevRequest={handleMovePrev}
        onMoveNextRequest={handleMoveNext} />
      {!imageLoaded && <Preloader fixed className={CommonCss.zIndexC} />}
    </>
  );
};

export default ImageViewer;
