
import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Preloader } from "nlib/ui";
import { getActiveOrganization } from "selectors/organizations";
import { getAllBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo, useState } from "react";
import SelectServiceBlock from "./lib/SelectServiceBlock";
import SelectTypeBlock from "./lib/SelectTypeBlock";
import StripeActions from "actions/StripeActions";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import classNames from "classnames";
import useAddNewBusiness from "hooks/useAddNewBusiness";

const { BUSINESS_TYPES } = Constants;

const STEP_SELECT_RPA = "selectRpa";

const STEP_SELECT_SERVICE = "selectService";

const STEP_COMPLETE = "complete";

const STEPS = [STEP_SELECT_RPA, STEP_SELECT_SERVICE, STEP_COMPLETE];

const FETCHING_DATA_TIMEOUT = 10000;

const AddNewBusiness = ({ onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const { id: organizationId, appsumoValue } = useSelector(getActiveOrganization);

  const userData = useSelector(getUserData);

  const businessesData = useSelector(getAllBusinessesData);

  const [lastAddedBusiness] = useMemo(() => {
    return Utils.arraySort(businessesData, "createdAt");
  }, [businessesData]);

  const addNewBusiness = useAddNewBusiness();

  const preselectBusinessType = useMemo(() => {
    const LS_KEY = `${Constants.LS_KEYS.ADD_NEW_BUSINESS_PRESELECT_RPA_TYPE}_${organizationId}`;

    const result = Utils.storageValue(LS_KEY, undefined, true);

    if (result) Utils.storageValue(LS_KEY, null, true);

    return result;
  }, [organizationId]);

  const [businessType, setBusinessType] = useState(() => {
    if (appsumoValue) return BUSINESS_TYPES.ROBOTIC;

    if (preselectBusinessType) return preselectBusinessType;

    if (lastAddedBusiness?.extraData) {
      const { uncategorizedRpaEnabled, reconciliationRpaEnabled } = lastAddedBusiness?.extraData;

      return uncategorizedRpaEnabled !== undefined || reconciliationRpaEnabled !== undefined
        ? BUSINESS_TYPES.ROBOTIC : BUSINESS_TYPES.REGULAR;
    }

    return undefined;
  });

  const [selectedService, setSelectedService] = useState(lastAddedBusiness?.extraData?.integrationService);

  const [currentStep, setCurrentStep] = useState(
    !userData.holdUser && preselectBusinessType ? STEPS[1] : STEPS[0]
  );

  const [fetching, setFetching] = useState(false);

  const completeSetup = useCallback(async() => {
    if (selectedService === IntegrationServices.QUICK_BOOKS.value
      && (businessType === BUSINESS_TYPES.ROBOTIC || !appsumoValue)) {
      const modalResult = await dispatch(UiActions.showModal(
        messages.onboardingQboConnectWarning,
        uiTexts.notification,
        true,
        undefined,
        uiTexts.continue
      ));

      if (!modalResult) return;
    }
    if (selectedService === IntegrationServices.QUICK_BOOKS_DESKTOP.value) {
      if (onClose) onClose();
      dispatch(UiActions.showAddQuickBooksDesktopWindow());
    } else {
      setFetching(true);
      Utils.storageValue(
        `${Constants.LS_KEYS.NEW_BUSINESS_RPA_MODE}_${organizationId}`,
        businessType === BUSINESS_TYPES.ROBOTIC ? true : null,
        true
      );
      addNewBusiness({ integrationService: selectedService, rpaMode: businessType === BUSINESS_TYPES.ROBOTIC });
      if (onClose) onClose();
    }
  }, [
    selectedService,
    businessType,
    appsumoValue,
    dispatch,
    messages.onboardingQboConnectWarning,
    uiTexts.notification,
    uiTexts.continue,
    onClose,
    organizationId,
    addNewBusiness
  ]);

  const handlePrevClick = useCallback(() => {
    const currentIndex = STEPS.indexOf(currentStep);

    if (currentIndex) {
      setCurrentStep(STEPS[currentIndex - 1]);
    } else if (onClose) onClose();
  }, [currentStep, onClose]);

  const handleNextClick = useCallback(() => {
    const currentIndex = STEPS.indexOf(currentStep);

    const nextStep = STEPS[currentIndex + 1];

    if (currentStep === STEP_SELECT_RPA && userData.holdUser) {
      setFetching(true);
      Utils.storageValue(
        `${Constants.LS_KEYS.ADD_NEW_BUSINESS_PRESELECT_RPA_TYPE}_${organizationId}`,
        businessType,
        true
      );
      setTimeout(() => setFetching(false), FETCHING_DATA_TIMEOUT);
      dispatch(StripeActions.createSetupCheckoutSession()).then((sessionUrl) => {
        window.location.href = sessionUrl;
      });
    } else if (nextStep === STEP_COMPLETE) {
      completeSetup();
    } else if (nextStep) {
      setCurrentStep(nextStep);
    }
  }, [currentStep, userData.holdUser, organizationId, businessType, dispatch, completeSetup]);

  return (
    <div className={Css.addNewBusiness}>
      <div className={Css.container}>
        <div className={Css.progress}>
          {STEPS.map((step) => {
            return <div key={step} className={classNames(Css.bar, currentStep === step && Css.active)} />;
          })}
        </div>
        <div className={Css.content}>
          {(() => {
            switch (currentStep) {
              case STEP_SELECT_RPA:
                return (
                  <SelectTypeBlock
                    businessType={businessType}
                    setBusinessType={setBusinessType} />
                );
              case STEP_SELECT_SERVICE:
                return (
                  <SelectServiceBlock
                    businessType={businessType}
                    selectedService={selectedService}
                    setSelectedService={setSelectedService} />
                );
              default:
                return <Preloader className={Css.preloader} />;
            }
          })()}
        </div>
        <div className={Css.footer}>
          <div>
            {(currentStep !== STEPS[0] || !!onClose) && (
              <Button
                outline large
                disabled={fetching}
                icon={currentStep === STEPS[0] ? Icons.X : Icons.CaretLeft}
                onClick={handlePrevClick}>
                {currentStep === STEPS[0] ? uiTexts.cancel : uiTexts.back}
              </Button>
            )}
          </div>
          <Button
            primary large
            disabled={(
              (currentStep === STEP_SELECT_SERVICE && !businessType)
              || (currentStep === STEP_SELECT_RPA && !businessType)
              || fetching
            )}
            icon={Icons.CaretRight}
            onClick={handleNextClick}>
            {uiTexts.next}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddNewBusiness);
