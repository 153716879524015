import Css from "./style.module.scss";

import signUpDemoIcon from "assets/signUpDemoIcon.svg";
import signUpPaymentIcon from "assets/signUpPaymentIcon.svg";

import * as Icons from "@phosphor-icons/react";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import React, { useCallback, useState } from "react";
import StripeActions from "actions/StripeActions";
import Utils from "utils/Utils";
import classNames from "classnames";

const FETCHING_DATA_TIMEOUT = 10000;

const { CALENDLY_URL } = Utils.getProcessEnvVars();

const PostSignUp = ({ className }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const [fetchingData, setFetchingData] = useState(false);

  const billingIssue = activeOrganization.billingId && !activeOrganization.subscriptionId;

  const [
    postSignUpModalDescriptionA,
    postSignUpModalDescriptionB,
    postSignUpModalDescriptionC
  ] = messages.postSignUpModalDescription;

  const [postSignUpModalSalesInfoA, postSignUpModalSalesInfoB] = messages.postSignUpModalSalesInfo;

  const [
    postSignUpModalPaymentInfoA,
    postSignUpModalPaymentInfoB,
    postSignUpModalPaymentInfoC,
    postSignUpModalPaymentInfoD
  ] = messages.postSignUpModalPaymentInfo;

  const handleTalkToSalesButtonClick = useCallback(() => {
    window.location.href = CALENDLY_URL;
  }, []);

  const handleAddPaymentMethodButtonClick = useCallback(() => {
    setFetchingData(true);
    setTimeout(() => {
      setFetchingData(false);
    }, FETCHING_DATA_TIMEOUT);
    dispatch(StripeActions.createSetupCheckoutSession()).then((sessionUrl) => {
      window.location.href = sessionUrl;
    });
  }, [dispatch]);

  return (
    <div className={classNames(Css.postSignUp, className, billingIssue && Css.billingIssue)}>
      <div className={Css.content}>
        <div className={Css.card}>
          <div className={Css.block}>
            <div className={Css.description}>
              <div className={Css.title}>{postSignUpModalDescriptionA}</div>
              <p className={classNames(billingIssue && Css.billingIssue)}>
                {billingIssue && <Icons.Warning />}
                <span>{billingIssue ? postSignUpModalDescriptionC : postSignUpModalDescriptionB}</span>
              </p>
            </div>
            <div className={Css.row}>
              <div className={Css.col}>
                <img src={signUpDemoIcon} />
                <h6>{postSignUpModalSalesInfoA}</h6>
                <p>{postSignUpModalSalesInfoB}</p>
                <Button
                  large
                  className={classNames(Css.button, Css.talkToSalesButton)}
                  onClick={handleTalkToSalesButtonClick}>
                  <span>{uiTexts.talkToSales}</span>
                  <Icons.CaretRight />
                </Button>
              </div>
              <div className={Css.col}>
                <img src={signUpPaymentIcon} />
                <h6>{postSignUpModalPaymentInfoA}</h6>
                <p>{billingIssue ? postSignUpModalPaymentInfoD : postSignUpModalPaymentInfoB}</p>
                {!billingIssue && <p className={Css.paymentNotice}>{postSignUpModalPaymentInfoC}</p>}
                <Button
                  large
                  primary
                  disabled={fetchingData}
                  className={Css.button}
                  onClick={handleAddPaymentMethodButtonClick}>
                  {fetchingData ? `${uiTexts.redirectingPleaseWait}...` : uiTexts.addPaymentMethod}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PostSignUp);
