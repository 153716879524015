import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useEffect } from "react";
import classNames from "classnames";

const { BUSINESS_TYPES } = Constants;

const AVAILABLE_FOR_RPA_SERVICES = IntegrationServices
  .getFullIntegratedList()
  .filter(({ availableForRpa }) => availableForRpa).map(({ value }) => value);

const SelectServiceBlock = ({ businessType, selectedService, setSelectedService }) => {
  const { uiTexts } = useSelector(getTextsData);

  const handleButtonClick = useCallback(({ currentTarget: { dataset: { service } } }) => {
    setSelectedService(service);
  }, [setSelectedService]);

  useEffect(() => {
    if (businessType === BUSINESS_TYPES.ROBOTIC && !AVAILABLE_FOR_RPA_SERVICES.includes(selectedService)) {
      setSelectedService(null);
    }
  }, [selectedService, setSelectedService, businessType]);

  return (
    <div className={Css.selectServiceBlock}>
      <div className={Css.header}>{uiTexts.selectConnection}</div>
      {IntegrationServices.getFullIntegratedList().map(({ value, label, logo, availableForRpa, offlineIntegration }) => {
        return (businessType === BUSINESS_TYPES.REGULAR || availableForRpa) && (
          <div
            key={value}
            data-service={value}
            className={classNames(Css.service, selectedService === value && Css.selected)}
            onClick={handleButtonClick}>
            <img key={value} src={logo} title={label} alt={label} />
            <span>{label}</span>
            {businessType === BUSINESS_TYPES.ROBOTIC && !offlineIntegration && !availableForRpa && (
              <span className={classNames(Css.badge, Css.soonBadge)}>{uiTexts.soon}</span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(SelectServiceBlock);
