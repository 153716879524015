import Css from "./style.module.scss";

import dropBox from "assets/dropBox.svg";

import { Button } from "shards-react";
import { bind, memoize } from "decko";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import Constants from "const/Constants";
import DropboxChooser from "react-dropbox-chooser";
import React, { PureComponent } from "react";
import Utils from "utils/Utils";

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

@connect(mapStateToProps)
class DropBoxPicker extends PureComponent {
  static SERVICE_NAME = "Dropbox";

  @memoize
  getFileDropAllowedExtensions() {
    return Object.values(Constants.DOCUMENT_FILE_TYPES).reduce(
      (aggregator, { extensions }) => {
        return [...aggregator, ...extensions];
      },
      []
    );
  }

  @bind
  successCallback(files) {
    files = files.map((file) => {
      const extension = `.${file.link.split(".").pop().toLowerCase()}`;

      const { mimeType } = Object.values(Constants.DOCUMENT_FILE_TYPES).find(({ extensions }) => extensions.includes(extension));

      return { ...file, mimeType };
    });
    this.props.onFilesPicked(files);
  }

  render() {
    const { DROPBOX_APP_KEY } = Utils.getProcessEnvVars();

    const { textsData: { uiTexts } } = this.props;

    const title = Utils.replaceTextVars(
      uiTexts.uploadDocumentsFromService,
      { service: DropBoxPicker.SERVICE_NAME }
    );

    return (
      <DropboxChooser
        multiselect
        linkType="direct"
        appKey={DROPBOX_APP_KEY}
        success={this.successCallback}
        extensions={this.getFileDropAllowedExtensions()}>
        <Button
          theme="light"
          title={title}
          className={Css.dropBoxPicker}>
          <img src={dropBox} alt={DropBoxPicker.SERVICE_NAME} />
          <span>{DropBoxPicker.SERVICE_NAME}</span>
        </Button>
      </DropboxChooser>
    );
  }
}

export default DropBoxPicker;
