import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import * as Yup from "yup";
import { getActiveOrganization, getAllUsersData } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserBusinessesData } from "selectors/businesses";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Checkbox from "nlib/ui/Checkbox";
import Constants from "const/Constants";
import FormLabel from "nlib/common/FormLabel";
import IncludeExclude from "nlib/ui/IncludeExclude";
import Input from "nlib/ui/Input";
import Modal, { ModalFooter } from "nlib/ui/Modal";
import React, { useCallback, useMemo, useState } from "react";
import Select from "nlib/ui/Select";
import Tooltip from "nlib/ui/Tooltip";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";

const INVITE_VALIDATION_SCHEMA = Yup.object().shape({
  userEmail: Yup.string().trim().email().required(),
  userRole: Yup.string().trim().required(),
  userPhone: Yup.string().trim().matches(Constants.PHONE_INPUT_PATTERN),
  emailNotifications: Yup.bool().required()
});

const USER_EMAIL_VALIDATION_SCHEMA = Yup.reach(INVITE_VALIDATION_SCHEMA, "userEmail");

const USER_PHONE_NUMBER_VALIDATION_SCHEMA = Yup.reach(INVITE_VALIDATION_SCHEMA, "userPhone");

const UserDetailsWindow = ({ initialEmail = "", disabled, onClose, ...restProps }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const businessesData = useSelector(getUserBusinessesData);

  const userData = useSelector(getUserData);

  const usersData = useSelector(getAllUsersData);

  const smsNotificationsAllowed = Constants.SMS_NOTIFICATIONS_ALLOWED_COUNTRIES
    .includes(activeOrganization.countryCode);

  const businessesOptions = useMemo(() => {
    return businessesData
      .sort((businessA, businessB) => businessA.name.localeCompare(businessB.name))
      .map(({ id, name }) => ({ value: id, label: name }));
  }, [businessesData]);

  const regularUsersData = useMemo(() => {
    return usersData.filter(({ guestUser }) => !guestUser);
  }, [usersData]);

  const editableUser = useMemo(() => {
    return initialEmail && usersData.find((user) => user.email === initialEmail);
  }, [initialEmail, usersData]);

  const initialRole = useMemo(() => {
    return (editableUser && editableUser.role) || "";
  }, [editableUser]);

  const initialBusinesses = useMemo(() => {
    if (!editableUser) {
      return {
        allAllowed: false,
        ids: []
      };
    }

    const userBusinessNames = editableUser
      ? editableUser.businessIds
      : [];

    return {
      allAllowed: !UserRoles.checkIsBusiness(initialRole) && editableUser && editableUser.businessIds.length === 0,
      ids: userBusinessNames
    };
  }, [editableUser, initialRole]);

  const initialEmailNotifications = useMemo(() => {
    return editableUser ? editableUser.emailNotifications : true;
  }, [editableUser]);

  const initialSmsNotifications = useMemo(() => {
    return editableUser ? !!editableUser.smsNotifications : false;
  }, [editableUser]);

  const initialPhoneNumber = useMemo(() => {
    return (editableUser && editableUser.phone) || "";
  }, [editableUser]);

  const [userEmail, setUserEmail] = useState(initialEmail);

  const [userPhoneNumber, setUserPhoneNumber] = useState(initialPhoneNumber);

  const [userRole, setUserRole] = useState(initialRole);

  const [userBusinesses, setUserBusinesses] = useState(initialBusinesses);

  const [emailNotifications, setEmailNotifications] = useState(initialEmailNotifications);

  const [smsNotifications, setSmsNotifications] = useState(initialSmsNotifications);

  const businessUserRoleSelected = useMemo(() => {
    return UserRoles.checkIsBusiness(userRole);
  }, [userRole]);

  const formDataValid = useMemo(() => {
    return INVITE_VALIDATION_SCHEMA.isValidSync({ userRole, userEmail, emailNotifications });
  }, [userEmail, userRole, emailNotifications]);

  const uniqueUserEmail = useMemo(() => {
    return !regularUsersData.some(({ email }) => {
      return email && userEmail && email.trim().toLowerCase() === userEmail.trim().toLowerCase();
    });
  }, [userEmail, regularUsersData]);

  const validEmail = useMemo(() => {
    return USER_EMAIL_VALIDATION_SCHEMA.isValidSync(userEmail) && uniqueUserEmail;
  }, [userEmail, uniqueUserEmail]);

  const validPhoneNumber = useMemo(() => {
    return USER_PHONE_NUMBER_VALIDATION_SCHEMA.isValidSync(userPhoneNumber)
      && !!Utils.normalizePhoneNumber(userPhoneNumber);
  }, [userPhoneNumber]);

  const formHasChanges = useMemo(() => {
    return !Utils.checkDeepEquality(
      {
        userRole,
        emailNotifications,
        smsNotifications,
        userPhoneNumber,
        allowAllBusinessesAccess: userBusinesses.allAllowed,
        userBusinesses: userBusinesses.ids
      },
      {
        userRole: initialRole,
        emailNotifications: initialEmailNotifications,
        smsNotifications: initialSmsNotifications,
        userPhoneNumber: initialPhoneNumber,
        allowAllBusinessesAccess: initialBusinesses.allAllowed,
        userBusinesses: initialBusinesses.ids
      }
    );
  }, [
    userRole,
    emailNotifications,
    smsNotifications,
    userBusinesses,
    userPhoneNumber,
    initialPhoneNumber,
    initialRole,
    initialEmailNotifications,
    initialSmsNotifications,
    initialBusinesses
  ]);

  const userRolesOptions = useMemo(() => {
    return UserRoles.getRoles().map(({ roleId, langId }) => {
      return {
        value: roleId,
        label: editableUser && (editableUser.guestUser || editableUser.sub === activeOrganization.creatorId)
          ? (editableUser.guestUser ? uiTexts.guestAccess : uiTexts.organizationOwner)
          : uiTexts[langId]
      };
    });
  }, [uiTexts, activeOrganization, editableUser]);

  const handleOkButtonClick = useCallback(() => {
    onClose({
      userEmail,
      userRole,
      userPhoneNumber,
      businessIds: userBusinesses.ids,
      emailNotifications,
      smsNotifications
    });
  }, [
    userEmail,
    userRole,
    userPhoneNumber,
    userBusinesses,
    emailNotifications,
    smsNotifications,
    onClose
  ]);

  const handleUserEmailInputChange = useCallback((value) => {
    setUserEmail(value);
  }, []);

  const handleUserPhoneInputChange = useCallback((value) => {
    setUserPhoneNumber(value);
  }, []);

  const handleUserRoleSelectChange = useCallback((value) => {
    const accountantAdmin = value === UserRoles.ACCOUNTANT_ADMIN.roleId;

    setUserRole(value);
    setUserBusinesses(() => ({
      allAllowed: accountantAdmin,
      ids: []
    }));
  }, []);

  const handleUserBusinessesInputChange = useCallback((value) => {
    setUserBusinesses((previousState) => ({
      ...previousState,
      ids: value.sort()
    }));
  }, []);

  const handleAllBusinessAllowedToggleChange = useCallback(() => {
    setUserBusinesses((previousState) => ({
      allAllowed: !previousState.allAllowed,
      ids: []
    }));
  }, []);

  const handleEmailNotificationsToggleChange = useCallback(() => {
    setEmailNotifications((previousState) => !previousState);
  }, []);

  const handleSmsNotificationsToggleChange = useCallback(() => {
    setSmsNotifications((previousState) => !previousState);
  }, []);

  const handleCloseClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const disabledOkButton = !formDataValid
    || (!userBusinesses.ids.length && !userBusinesses.allAllowed)
    || (initialEmail ? !formHasChanges : !uniqueUserEmail)
    || (smsNotificationsAllowed && smsNotifications && !validPhoneNumber);

  const disabledRoleSelect = disabled
    || userData.email === initialEmail
    || (!initialEmail && !validEmail)
    || (editableUser && editableUser.sub === activeOrganization.creatorId)
    || (editableUser && editableUser.guestUser);

  return (
    <Modal
      {...restProps}
      iconComponent={initialEmail ? Icons.User : Icons.UserPlus}
      title={initialEmail ? uiTexts.editUser : uiTexts.inviteUser}
      className={Css.userDetailsWindow}
      contentClassName={Css.contentClassName}
      onClose={onClose}>
      <div className={Css.row}>
        <div className={Css.col}>
          <FormLabel>{uiTexts.email}</FormLabel>
          <div className={Css.relative}>
            <Input
              type="email"
              disabled={disabled || initialEmail}
              placeholder={uiTexts.enterEmail}
              value={userEmail}
              invalid={!validEmail}
              onChange={handleUserEmailInputChange} />
            <Tooltip contentClassName={Css.tooltip} opened={!initialEmail && !uniqueUserEmail}>
              <Icons.Warning />
              <span>{uiTexts.userAlreadyAdded}</span>
            </Tooltip>
          </div>
        </div>
        <div className={Css.col}>
          <FormLabel>{uiTexts.role}</FormLabel>
          <div className={Css.relative}>
            <Select
              disabled={disabledRoleSelect}
              value={userRole}
              placeholder={uiTexts.selectRole}
              invalid={!disabledRoleSelect && !userRole}
              options={userRolesOptions}
              onChange={handleUserRoleSelectChange} />
          </div>
        </div>
      </div>
      {(initialEmail || (validEmail && userRole)) && (
        <>
          <FormLabel className={Css.row}>
            <div>{uiTexts.allowAccessToBusinesses}</div>
          </FormLabel>
          {!businessUserRoleSelected && (
            <div className={Css.row}>
              <Checkbox
                toggle
                checked={userBusinesses.allAllowed}
                disabled={disabled || (!initialEmail && (!validEmail || !userRole))}
                onChange={handleAllBusinessAllowedToggleChange}>
                {messages.allowAllBusinessesAccess}
              </Checkbox>
            </div>
          )}
          {(!userBusinesses.allAllowed || businessUserRoleSelected) && (
            <div className={Css.row}>
              <div className={`${Css.col} ${Css.hidden}`}>
                <FormLabel>{uiTexts.businesses}</FormLabel>
                <IncludeExclude
                  className={Css.includeExclude}
                  disabled={disabled || userBusinesses.allAllowed || (editableUser && editableUser.guestUser)}
                  emptyPlaceholder={uiTexts.noBusinessesFound}
                  value={userBusinesses.ids}
                  options={businessesOptions}
                  onChange={handleUserBusinessesInputChange} />
              </div>
            </div>
          )}
        </>
      )}
      <FormLabel className={Css.row}>
        <div>{uiTexts.notifications}</div>
      </FormLabel>
      <div className={Css.row}>
        <Checkbox
          toggle
          checked={emailNotifications}
          disabled={disabled}
          onChange={handleEmailNotificationsToggleChange}>
          <span>{messages.allowEmailNotifications}</span>
        </Checkbox>
      </div>
      {smsNotificationsAllowed && (
        <>
          <div className={Css.row}>
            <Checkbox
              toggle
              checked={smsNotifications}
              disabled={disabled}
              onChange={handleSmsNotificationsToggleChange}>
              <span>{messages.allowSmsNotifications}</span>
            </Checkbox>
          </div>
          {smsNotifications && (
            <div className={Css.row}>
              <div className={Css.col}>
                <div>{uiTexts.phone}</div>
                <Input
                  type="tel"
                  disabled={disabled}
                  placeholder={uiTexts.enterPhoneNumber}
                  value={userPhoneNumber}
                  invalid={!validPhoneNumber}
                  onChange={handleUserPhoneInputChange} />
              </div>
            </div>
          )}
        </>
      )}
      <ModalFooter>
        <Button
          primary large
          disabled={disabledOkButton}
          onClick={handleOkButtonClick}>
          {initialEmail ? uiTexts.save : uiTexts.invite}
        </Button>
        <Button
          outline large
          onClick={handleCloseClick}>{uiTexts.cancel}</Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(UserDetailsWindow);
